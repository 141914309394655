<template>
  <router-view />
</template>



<script setup >
import { ref, onMounted, reactive } from 'vue'
import { request } from "./network/request"
import store from './store'
request({
  url: '/init?stam='+new Date().getTime(),
}).then(initRes => {
  localStorage.setItem('initData', JSON.stringify(initRes))
  store.state.initData = initRes
})

</script>

<style lang="less">
@import './assets/css/variable.css';
@import './assets/css/clean.css';

// .el-table__body-wrapper{
//     max-height: 470px !important;
// }
</style>
