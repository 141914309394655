import { createRouter, createWebHashHistory } from 'vue-router'

import createAdminRouters from './createAdminRouters.js'
const adminRoutes = createAdminRouters()



const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    { path: '/', name: 'Home', component: () => import('@/views/Home.vue'), redirect: '/admin/OrderCenter' },
    { path: '/about', name: 'About', component: () => import('@/views/About.vue') },
    { path: '/client', name: 'About', component: () => import('@/views/Client.vue') },
    { path: '/admin', name: 'Admin', component: () => import('@/admin/layouts/base_layout.vue'), redirect: '/admin/classify', children: adminRoutes, },
  ]
})



	import NProgress from 'nprogress'
	import 'nprogress/nprogress.css'

	//全局进度条的配置
	NProgress.configure({ 
	  showSpinner: true,  //加载微调器设置,默认为true
	  //使用缓动（CSS缓动字符串）和速度（以毫秒为单位）调整动画设置。（默认：ease和200）
	  easing: 'ease',
    showSpinner:false,
	  speed: 1000,
	})

	//对路由钩子函数进行设置
	//路由进入前
	router.beforeEach((to, from , next) => {
    console.log("ahshsah")
	  NProgress.start();
	  next();
	});
	//路由进入后
	router.afterEach(() => {  
	  NProgress.done()
	})

// router.beforeEach((to, from) => {
//   console.log(to)
//   // ...
//   // 返回 false 以取消导航

// })

router.adminRoutes = adminRoutes


export default router
